<template>
  <v-container fluid >
    <v-row dense align="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" text :dark="color1IsDark">
            <v-toolbar-title>Players</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              clearable
              v-model="search"
              solo-inverted
            ></v-text-field>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="players"
              :options.sync="pagination"
              item-key="id"
              :loading="loading"
              :search="search"
              :footer-props="fProps"
              dense
              multi-sort
            >
              <template v-slot:progress>
                <v-progress-linear color="color3" indeterminate></v-progress-linear>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-btn
                  color="color3"
                  icon text
                  :to="{name: 'player-profile', params: {playerProfileId: item.id}}"
                >
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="user.vbl">
        <v-btn color="color3" text @click.stop="upload = !upload">
          <v-icon class="mr-2">fas fa-cloud-upload-alt</v-icon> Upload
        </v-btn>
      </v-col>
      <v-col class="text-right" cols="6" >
        <v-btn color="color3" text @click.stop="onDownloadClick">Download</v-btn>
      </v-col>
    </v-row>

    <v-row dense v-if="upload">
      <v-col cols="12">
        <profile-upload :username="username"></profile-upload>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileUpload from '@/components/User/Importer/Importer.vue'
import Papa from 'papaparse'
import { usPhone } from '@/Filters'

export default {
  props: ['username'],
  data () {
    return {
      search: null,
      loading: false,
      players: [],
      headers: [
        { text: 'Id', value: 'id', align: 'left' },
        { text: 'First Name', value: 'firstName', align: 'left' },
        { text: 'Last Name', value: 'lastName', align: 'left' },
        { text: 'City', value: 'city', align: 'left' },
        { text: 'State', value: 'state', align: 'left' },
        { text: 'Club', value: 'club', align: 'left' },
        { text: 'Grad Year', value: 'gradYear', align: 'left' },
        { value: 'actions', sortable: false, align: 'end' }
      ],
      pagination: { sortBy: ['id'], itemsPerPage: 50 },
      fProps: { itemsPerPageOptions: [50, 100, 200] },
      editablePlayer: {
        id: 0
      },
      dialog: false,
      edit: false,
      saving: false,
      compareTo: null,
      upload: false
    }
  },
  computed: {
    ...mapGetters(['user', 'color1IsDark']),
    refs () {
      return this.$refs
    },
    selectedPlayer () {
      return this.players.find(f => f.id === this.editablePlayer.id)
    },
    comparePlayer () {
      return this.compareTo && this.players.find(f => f.id === this.compareTo)
    },
    dirty () {
      return this.editablePlayer && this.selectedPlayer && JSON.stringify(this.editablePlayer) !== JSON.stringify(this.selectedPlayer)
    },
    popPlayers () {
      var x = [this.editablePlayer]
      if (this.comparePlayer) x.push(this.comparePlayer)
      return x
    },
    dto () {
      if (!this.dirty) return null
      const result = { id: this.editablePlayer.id }
      Object.keys(this.editablePlayer).forEach(k => {
        const a = this.selectedPlayer[k]
        const b = this.editablePlayer[k]
        if (a !== b) {
          result[k] = b
        }
      })

      return result
    },
    dropDown () {
      return this.players.map(m => {
        return {
          value: m.id,
          text: `${m.id}: ${m.firstName} ${m.lastName}`
        }
      })
    },
    emailData () {
      return this.players.filter(f => !!f.email).map(p => {
        return {
          Firstname: p.firstName,
          Lastname: p.lastName,
          Fullname: `${p.firstName} ${p.lastName}`,
          Email: p.email,
          Phone: usPhone(p.phone),
          State: p.state
        }
      })
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$VBL.get.players(this.username)
        .then(r => {
          this.players = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    selectPlayer (player) {
      this.editablePlayer = Object.assign({}, player)
      this.dialog = true
    },
    cancel () {
      this.editablePlayer = { id: 0 }
      this.compareTo = null
      this.dialog = false
    },
    save () {
      this.saving = true
      this.$VBL.patch.player(this.dto)
        .then(r => {
          Object.assign(this.selectedPlayer, r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.saving = false
          this.cancel()
        })
    },
    onDownloadClick () {
      var fileName = `${this.username}_players.csv`
      var data = this.emailData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: true }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  components: {
    ProfileUpload
  },
  watch: {
    dialog: function (n, o) {
      if (o && !n) this.cancel()
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style>

</style>
