<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Player Profile Importer</v-toolbar-title>
    </v-toolbar>
    <loading-bar v-model="loading"></loading-bar>
    <!-- TITLE ROW -->
    <v-card-title class="title font-weight-regular justify-space-between" ref="topOfPage">
      <span>{{ currentStep.title }}</span>
      <v-avatar
        color="color3"
        class="color3Text--text"
        size="24"
        v-text="window + 1"
      ></v-avatar>
    </v-card-title>
    <!-- WINDOW STEPPER -->
    <v-card-text class="pt-0">
      <v-window v-model="window" touchless>
        <v-window-item v-for="step in steps" :key="step.key" :value="step.value">
          <!-- FILE PICKER -->
          <v-card color="grey lighten-4" v-if="step.type === 'select-file'">
            <v-container>
              <v-row dense>
                <v-col cols="12" class="title">
                  {{ currentFile ? 'You are currently using:' : 'Which file would you like to upload?'}}
                </v-col>
                <v-expand-transition>
                  <v-col cols="12" v-if="currentFile" class="subheading">
                    {{currentFile.name}}
                  </v-col>
                </v-expand-transition>
                <v-col cols="12">
                  <v-btn
                    raised
                    large
                    class="color3 white--text"
                    @click="onPickFile"
                    :disabled="busy"
                  >{{`Choose a ${currentFile ? 'new ' : ''}File`}}</v-btn>
                  <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    accept=".csv"
                    @change="onFilePicked">
               </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- VERIFY DATA -->
          <v-card color="grey lighten-4" v-if="step.type === 'verify-data'">
            <v-container>
              <v-row dense>
                <v-col cols="12" class="title">
                  Make sure this is the right data
                  <div class="subheading mt-1">Double check if the column headers are correct and uncheck the box below if your file did not have headers</div>
                </v-col>
                <v-col cols="12" class="title">
                  <v-checkbox
                    color="color3"
                    label="My file has column headers"
                    v-model="hasHeader"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :items="rows"
                    :headers="headers"
                    v-if="parseResuls"
                    class="elevation-1"
                    dense
                  >
                  </v-data-table>
               </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- COLUMN MAPPING -->
          <v-card color="grey lighten-4" v-if="step.type === 'column-mapping'">
            <v-container>
              <v-row dense>
                <v-col cols="12" class="title">
                  Column mapping
                  <div class="subheading mt-1">
                    Map the column of your .CSV file to the values needed by choosing the column
                    header from the drop down list. If your .CSV file does not contain the needed
                    value select "Not Available"
                  </div>
                </v-col>
              </v-row>
              <v-form v-model="validMap" ref="form">
                <v-data-table
                  hide-default-footer
                  :items="regFields"
                  :headers="mapHeaders"
                  v-if="parseResuls"
                  class="elevation-1"
                  style="width: 880px; min-width: 880px; max-width: 880px"
                >
                  <template v-slot:item="{item}">
                    <tr>
                    <td class="text-right" style="width: 180px; min-width: 180px; max-width: 180px; white-space: nowrap; text-overflow: ellipsis;">{{item.name}}</td>
                    <td style="width: 250px; min-width: 250px; max-width: 250px;">
                      <v-select
                        :items="selectOptions"
                        v-model="item.value"
                        label="Column*"
                        hint="Choose a column"
                        dense
                        single-line
                        color="color3"
                        item-color="color3"
                        :rules="[(v) => !!v || 'Choose a column']"
                        style="white-space: nowrap; text-overflow: ellipsis;"
                      >
                        <template slot="item" slot-scope="data">
                          <v-divider v-if="data.divider"></v-divider>
                          <v-subheader v-else-if="data.header">{{data.header}}</v-subheader>
                          <v-list-item-content v-else>{{data.item.text}}</v-list-item-content>
                        </template>
                      </v-select>
                    </td>
                    <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                      {{parseResuls.data[0] ? parseResuls.data[0][item.value] : ""}}
                    </td>
                    <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                      {{parseResuls.data[1] ? parseResuls.data[1][item.value] : ""}}
                    </td>
                    <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                      {{parseResuls.data[2] ? parseResuls.data[2][item.value] : ""}}
                    </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-form>
              <v-row dense>
                <v-col cols="12">
                  <v-btn color="color3" class="xsmall" text @click.stop="onNaClick">Mark all remaining as Not Available</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- CONFIRM -->
          <v-card color="grey lighten-4" v-if="step.type === 'confirm'">
            <v-container>
              <v-row dense>
                <v-col cols="12" class="title" v-if="parseResuls">
                  You are about to import the {{toBeImported.length}} profiles
                  in {{currentFile.name}}
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    color="color3"
                    label="I have reviewed all the mappings and would like to complete the upload"
                    v-model="mapConfirmed"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-divider></v-divider>
    <!-- BUTTONS -->
    <v-card-actions>
      <!-- BACK -->
      <v-fab-transition>
        <v-btn
          v-if="previousStep"
          color="color3Text color3--text"
          fab
          @click="onBackClick"
        ><v-icon>fas fa-caret-left</v-icon></v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          v-if="previousStep"
          color="color3"
          text
          @click="onBackClick"
        >Back</v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <!-- NEXT -->
      <v-fab-transition>
        <v-btn
          v-if="nextBtn && nextBtn.show"
          color="color3"
          text
          :disabled="nextBtn.disabled"
          @click="nextBtn.onClick"
        >{{nextBtn.text}}</v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          v-if="nextBtn && nextBtn.show"
          color="color3 color3Text--text"
          fab
          :disabled="nextBtn.disabled"
          :loading="nextBtn.loading"
          @click="nextBtn.onClick"
        ><v-icon>{{nextBtn.icon}}</v-icon></v-btn>
      </v-fab-transition>
    </v-card-actions>

    <v-dialog v-model="workingDialog" max-width="500px" persistent>
      <v-card v-if="busy">
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Working!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          <h3>Please wait, we are</h3>
          <v-progress-circular indeterminate v-bind:size="50" color="color3"></v-progress-circular>
          <h3>uploading your registrations.</h3>
        </v-card-text>
      </v-card>
      <v-card v-else-if="error">
        <v-toolbar color="error">
          <v-toolbar-title>Error!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          We seemed to have had an issue<br>
          {{error}}<br>
          If the problem persists please contact support.
        </v-card-text>
        <v-card-actions>
          <v-btn @click.stop="workingDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Success!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center title">
          Your upload is complete.
          <div class="subheading">We are still verifying numbers and calculating points. The teams will start to appear on your teams page over the next few minutes.</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="complete" text color="color3">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Papa from 'papaparse'
import moment from 'moment'
// import PlayerProfile from '@/classes/PlayerProfile'
const NA = '~~~'

export default {
  props: ['username', 'active'],
  data () {
    return {
      window: 0,
      loading: false,
      busy: false,
      currentFile: null,
      parseResuls: null,
      regFields: [
        { name: 'First Name', value: '', map: ['first,name', 'name'] },
        { name: 'Last Name', value: '', map: ['last,name', 'name'] },
        { name: 'AAU Number', value: '', map: ['aau'] },
        { name: 'AVP Number', value: '', map: ['avp'] },
        { name: 'Phone', value: '', map: ['phone'] },
        { name: 'Email', value: '', map: ['email'] },
        { name: 'Birthdate', value: '', map: ['birth', 'dob'] },
        { name: 'Address', value: '', map: ['address'] },
        { name: 'City', value: '', map: ['city'] },
        { name: 'State', value: '', map: ['state', 'st'], not: ['first', 'last'] },
        { name: 'Zip', value: '', map: ['zip', 'postal'] },
        { name: 'Club', value: '', map: ['club'] }
      ],
      hasHeader: true,
      divisionColumn: null,
      divisionList: null,
      divisionMap: null,
      validMap: false,
      validDivMap: false,
      mapHeaders: [
        { text: 'Value Needed', sortable: false, align: 'right' },
        { text: 'Choose A Column', sortable: false, align: 'left' },
        { text: 'Sample Data 1', sortable: false, align: 'left' },
        { text: 'Sample Data 2', sortable: false, align: 'left' },
        { text: 'Sample Data 3', sortable: false, align: 'left' }
      ],
      overwrite: false,
      mapConfirmed: false,
      workingDialog: false,
      error: null
    }
  },
  computed: {
    steps () {
      let s = 1
      let steps = [
        {
          value: s++,
          type: 'select-file',
          key: 'select-file',
          title: 'Upload File',
          valid: !!this.currentFile,
          skip: false
        },
        {
          value: s++,
          type: 'verify-data',
          key: 'verify-data',
          title: 'Verify Data',
          valid: true,
          skip: false,
          nextText: 'Yep, it looks good'
        },
        {
          value: s++,
          type: 'column-mapping',
          key: 'column-mapping',
          title: 'Map The Columns',
          valid: this.validMap,
          skip: false
        },
        {
          value: s++,
          type: 'confirm',
          key: 'confirm',
          title: 'Confirm & Upload',
          valid: this.mapConfirmed,
          skip: false,
          complete: true,
          nextText: 'Just import them already!',
          nextIcon: 'fas fa-upload'
        }
      ]

      s = 0
      steps = steps.filter(f => !f.skip)
      steps.forEach(f => { f.value = s++ })

      return steps
    },
    currentI () {
      return this.steps.findIndex(f => f.value === this.window)
    },
    currentStep () {
      return this.steps.find(f => f.value === this.window)
    },
    nextStep () {
      return this.steps[this.currentI + 1]
    },
    previousStep () {
      return this.steps[this.currentI - 1]
    },
    nextBtn () {
      return this.currentStep ? {
        show: this.nextStep || this.currentStep.complete,
        disabled: !this.currentStep.valid,
        onClick: this.currentStep.complete ? this.onCompleteClick : this.onNextClick,
        text: this.currentStep.nextText || 'Next',
        icon: this.currentStep.nextIcon || 'fas fa-caret-right',
        loading: this.loading
      } : null
    },
    selectOptions () {
      return [
        { text: 'Not Available', value: NA },
        { divider: true },
        { header: 'Columns' },
        ...this.columnOptions
      ]
    },
    columnOptions () {
      const options = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          options.push({ text: this.hasHeader ? `${prop}` : `Col ${prop}`, value: prop })
        }
      }
      return options
    },
    rows () {
      return this.parseResuls && this.parseResuls.data
    },
    headers () {
      const head = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          head.push({
            text: this.hasHeader ? `${prop}` : `Col ${prop}`,
            value: `${prop}`,
            sortable: false,
            align: 'left'
          })
        }
      }
      return head
    },
    unMappedFields () {
      return this.regFields.filter(f => !f.value)
    },
    mappedFields () {
      var result = {}
      for (const f of this.regFields) {
        result[f.name] = f.value
      }
      return result
    },
    toBeImported () {
      return this.parseResuls && this.parseResuls.data
    }
  },
  methods: {
    onBackClick () {
      if (!this.previousStep) return
      this.window = this.previousStep.value
    },
    onNextClick () {
      this.$refs && this.$refs.form && this.$refs.form[0].validate()
      if (!this.nextStep) return
      this.window = this.nextStep.value
      this.$vuetify.goTo(this.$refs.topOfPage, { offset: -50 })
    },
    onCompleteClick () {
      console.log(this.mapProifles())
      this.upload()
    },
    onPickFile () {
      this.$refs.fileInput[0].click()
    },
    onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) return
      if (file.name.lastIndexOf('.csv') <= 0) {
        alert('Please select a CSV file')
        return
      }
      this.busy = true
      this.currentFile = file
      this.parseFile()
      this.onNextClick()
      this.busy = false
    },
    parseFile () {
      Papa.parse(this.currentFile, {
        header: this.hasHeader,
        complete: (results, file) => {
          this.currentFile = file

          for (const e of results.errors) {
            results.data.splice(e.row, 1)
          }
          const checkProperties = (obj) => {
            for (var key in obj) {
              if (obj[key] !== null && obj[key] !== '') return true
            }
            return false
          }

          results.data = results.data.filter((r) => checkProperties(r))

          this.parseResuls = results
          this.tryMap()
        }
      })
    },
    tryMap () {
      const dc = this.selectOptions.filter(f => f.value).filter(f => f.value.toLowerCase().includes('division'))
      if (dc.length > 0) this.divisionColumn = dc[0].value
      const fields = this.regFields.filter((f) => { return f.map && f.map.length > 0 })

      for (const field of fields) { // each field that has a map array
        for (const m of field.map) {
          let options = this.selectOptions.filter(f => f.value) // column headers
          const filters = m.split(',')
          for (const filter of filters) {
            options = options.filter(o => o.value.toLowerCase().includes(filter.toLowerCase()))
          }
          if (field.not) {
            for (const x of field.not) {
              options = options.filter(o => !o.value.toLowerCase().includes(x.toLowerCase()))
            }
          }

          if (options.length > 0) {
            field.value = options[0].value
            break
          }
        }
      }
    },
    clear () {
      this.currentFile = null
      this.parseResuls = null
      this.$refs.fileInput[0].value = null
      this.window = 0
    },
    onNaClick () {
      this.unMappedFields.forEach(f => {
        f.value = NA
      })
    },
    mapProifles () {
      const players = []

      if (!this.mappedFields || !this.parseResuls || !this.parseResuls.data) {
        return players
      }

      const map = this.mappedFields
      for (const record of this.toBeImported) {
        const player = {}

        if (map['First Name'] === map['Last Name']) {
          var name = record[map['First Name']].trim().toLowerCase().replace(/(^|\s)[a-z]/g, function (f) { return f.toUpperCase() }).split(' ')
          player.lastName = name.pop()
          player.firstName = name.join(' ')
        } else {
          player.firstName = record[map['First Name']].trim().toLowerCase().replace(/(^|\s)[a-z]/g, function (f) { return f.toUpperCase() })
          player.lastName = record[map['Last Name']].trim().toLowerCase().replace(/(^|\s)[a-z]/g, function (f) { return f.toUpperCase() })
        }
        player.phone = record[map.Phone]
        player.email = record[map.Email]
        const dob = record[map.Birthdate]
        if (dob) {
          player.dob = moment(record[map.Birthdate]).format('YYYY-MM-DD')
          if (player.dob === 'Invalid date') player.dob = null
        }
        player.address = record[map.Address]
        player.city = record[map.City]
        player.state = record[map.State]
        player.zip = record[map.Zip]
        player.aauNumber = record[map['AAU Number']]
        player.avpNumber = record[map['AVP Number']]
        player.club = record[map.Club]

        players.push(player)
      }

      return players
    },
    upload () {
      this.busy = true
      this.workingDialog = true
      this.error = null

      const players = this.mapProifles()
      this.$VBL.post.playerProfile(players, this.username)
        .catch((e) => {
          this.error = e.response.data
          console.log(e)
        })
        .finally(() => { this.busy = false })
    },
    complete () {
      this.$emit('complete')
      this.workingDialog = false
    }
  },
  watch: {
    active: function (n, o) {
      if (o && !n) this.clear()
    },
    hasHeader () {
      this.parseFile()
    }
  }
}
</script>

<style>

</style>
